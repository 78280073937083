
export default {
  name: "buy",
  layout: "single",
  data() {
    return {
      amount: 0,
      createOrderFlag: false,
      selectedReceive: null,
      receives: [
        { label: "کیف پول تتر" },
        // { label: "برداشت مستقیم" },
        // {icon: "/layouts/icons/networks/trx.svg", label: "ERC20"},
      ],
    }
  },
  computed: {
    rialAmount() {
      return parseInt(this.$store.state.usdtConfig.currentPrice * this.amount);
    },
    fee() {
      if (this.amount <= 200) {
        return 1;
      } else {
        return parseFloat(parseFloat((this.amount / 100) * this.$auth.user.wage.fee).toFixed(2));
      }
    }
  },
  methods: {
    goEdit() {
      this.$router.push('/');
    },
    async createOrder(type = 'wallet') {
      this.createOrderFlag = true;
      if (type === 'wallet') {
        this.$store.dispatch("overlay/toggleOverlay");
        try {
          let resp = await this.$axios.$post('/v1/order/buy', { amount: this.amount });
          let date = new Date(resp.order.createdAt).toLocaleDateString('fa-IR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          });
          let dateTime = new Date(resp.order.createdAt).toLocaleTimeString('fa-IR');
          resp.order.date = `${dateTime} - ${date}`;
          await this.$store.dispatch("buySell/clearOrder");
          await this.$auth.fetchUser();
          await this.$router.push({ name: 'dashboard-orders', params: { showOrder: resp.order } });
        } catch (err) {
          this.$store.dispatch("messagePopup/error", err.response.data.message);
        } finally {
          this.$store.dispatch("overlay/toggleOverlay");
        }
      } else if (type === 'payment') {

      }
    }
  },
  async fetch() {
    if (this.$store.state.buySell.type === 'buy' && this.$store.state.buySell.amount >= 10) {
      this.amount = this.$store.state.buySell.amount;
    }
  },
  beforeMount() {
    this.$store.dispatch("buySell/loadFromLocal");
    if (this.$store.state.buySell.type === 'buy' && this.$store.state.buySell.amount >= 10) {
      this.amount = this.$store.state.buySell.amount;
    } else {
      this.$router.push('/');
    }
  },
  destroyed() {
    if (this.$store.state.buySell.type && this.$store.state.buySell.amount) {
      this.$store.dispatch("buySell/setToLocal");
    }
  },
}
