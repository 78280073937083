
export default {
  name: 'FAQPage',

  data() {
    return {
      faq: [
        {
          title: 'تتر'
        },
        {
          question: 'تتر(USDT)چیست؟',
          answer: 'تتر (Tether) با واحد اختصاری USDT یک ارزدیجیتال با پشتوانه دلار است که قیمتی همیشه ثابت دارد. بهای هر تتر، همواره برابر با یک دلار ایالات متحده آمریکا است. در حال حاضر، تتر یکی از محبوب‌ترین استیبل کوینStable Coin))های بازار است که معامله‌گران به‌منظور حفظ سرمایه‌های خود در مقابل نوسانات بازار، از آن استفاده می‌کنند.'
        },
        {
          question: 'تتر ترون چیست؟',
          answer: 'USDT-TRC 20مبتنی بر بلاک چین ترون که در حال حاضر نسبت به بقیه شبکه انتقال تتر فراگیر تر بوده و درمیان کاربران از محبوبیت بیشتری برخوردار است.'
        },
        {
          question: 'شبکه ترون چه قابلیت هایی را داراست؟',
          answer: 'انتقال تتر به این شبکه، حتی از انتقالش به اتریوم موفق تر بوده و از همان ابتدا توانست نظر بسیاری از سرمایه گذارها  و تریدر ها را جذب این شبکه کند. دلایل اصلی عملکرد خوب Tether TRC20 ، سرعت بیشتر و کارمزد کمتر این شبکه نسبت به اتریوم هست.'
        },
        {
          question: 'تفاوت TRX و TRC20 چیست؟',
          answer: 'TRX نام اختصاری ارز Tronix، ارز بومی پلتفرم ترون است اما TRC20، استانداردی است که توکن‌های دیگر می‌توانند با رعایت آن بر روی بلاک چین ترون ایجاد شوند. برای تولید توکن‌ها بر اساس استاندارد TRC20، باید کارمزدی در قالب ارز TRX پرداخت کنید.'
        },
        {
          question: 'کارمزد(فی) شبکه TRC20 چه مقدار است؟',
          answer: 'در حال حاضر، کارمزد انتقال ارزها دیجیتال در شبکه TRC20 چیزی حدود ۱تتر است.'
        },
        {
          question: 'چگونه از آدرس تتر در کیف پول نوع شبکه را تشخیص دهیم؟',
          answer: 'آدرس USDT-TRC20 با T شروع می‌شود که مشابه آدرس‌های شبکه ترون است.'
        },
        {
          question: 'آیا امکان انتقال تتر TRC20 به بلاک چین متفاوت ممکن است؟',
          answer: 'خیر، ارزهای دیجیتال نباید به شبکه‌های اشتباه ارسال شوند و در صورت ارسال تتر TRC20 به آدرس کیف پول  در بلاک چین دیگر، تترهای شما از بین خواهد رفت.'
        },
        {
          question: 'تتر TRC20 کجا نگهداری کنیم؟',
          answer: 'کیف پول تتری تتر اکسچنچ کاربران می توانند دارایی ارز دیجیتال خود را در کیف پول ارزی تتر اکسچنج نگهداری نمایند.'
        },
        {
          question: 'چرا خرید تتر اهمیت دارد؟',
          answer: 'تتر یک ارز پایدار با پشتوانه دلار است. ارزش هر توکن تتر همواره برابر با یک دلار و ثابت است. به‌علت مزایایی مانند عدم نوسانات قیمتی، سرعت بسیار بالا، کارمزدهای به‌صرفه و نقدشوندگی مثال زدنی، تتر برای انجام معاملات در بازار رمز ارزها یک گزینه بسیار مناسب محسوب می شود.'
        },
        {
          title: 'تتر اکسچنج'
        },
        {
          question: 'نوع تتری که تتر اکسچنج مبادله می کند چیست؟',
          answer: 'تتر اکسچنج به دلیل کارمزد پایین و سرعت انتقال بالای شبکه ترون تنها تتر TRC20 را ارائه می دهد.'
        },
        {
          question: 'چطور به تتر اکسچنج اعتماد کنم؟',
          answer: 'به‌طورکلی، اعتبار پلتفرم تبادل در اکوسیستم رمزارزها به چند شاخص مهم مانند سابقه فعالیت، اصالت، نرخ تبادل رمزارز، پشتیبانی ۲۴ساعته و امنیت بستگی دارد. شما در ابتدا می توانید با درخواست های کوچکتر شروع کنید و به مرور پس از کسب اطمینان از تتر اکسچنج اقدام به درخواست های بزرگتر نمایید.'
        },
        {
          question: 'ساعات معاملات به چه صورت است؟',
          answer: 'تتر اکسچنج بصورت ۲۴ساعته، ۷روز هفته و ۳۶۵روز سال خدمات خرید و فروش تتر را از طریق وب‌سایت و اپلیکیشن ارائه می‌دهد.'
        },
        {
          question: 'کارمزد تتر اکسچنج در خرید و فروش تتر به چه صورت می باشد ؟',
          answer: 'کارمزد با توجه به صفحه کارمزد ها که در سایت قرار دارد محاسبه می شود.'
        },
        {
          question: 'شرایط و قوانین معامله با تتر اکسچنج به چه صورت است؟',
          answer: 'برای آگاهی از شرایط معامله می‌توانید به بخش شرایط و قوانین وب‌سایت تتر اکسچنج مراجعه کنید.'
        },
        {
          question: 'اپلیکیشن تتر اکسچنج را چگونه نصب کنم؟',
          answer: 'شما می‌توانید اپلیکیشن تتر اکسچنج را علاوه‌بر گوگل پلی از طریق کافه‌بازار و وب‌سایت تتر اکسچنج به‌صورت دانلود مستقیم نصب کنید. '
        },
        {
          question: 'راه های ارتباطی با مجموعه به چه صورت می باشد؟',
          answer: 'کاربران می توانند از طریق چت پشتیبانی و یا تلفن های مجموعه به شماره های زیر با کارشناسان ما در ارتباط باشند.'
        },
        {
          question: 'آیا امکان نگهداری تتر در مجموعه امکان پذیر می باشد؟',
          answer: 'بله، امکان نگهداری تتر با استفاده از سرویس "کیف تتر" در تتر اکسچنج وجود دارد.'
        },
        {
          title: 'احراز هویت'
        },
        {
          question: 'چرا احراز هویت نیاز است؟',
          answer: 'یکی از ویژگی‌های شبکه بلاکچین و بیت کوین غیرقابل برگشت بودن تراکنش‌های آنها است. فروشندگان برای کاهش ریسک موجود در خرید و فروش رمز ارز ها و همچنین عدم سو استفاده کلاهبرداران اینترنتی بر اساس قوانین موجود در بازارهای مالی دنیا نیاز است که هر فرد تنها با هویت و کارت بانکی خود اقدام به معامله ،واریز و برداشت نماید تا از این گونه مشکلات جلوگیری شود.'
        },
        {
          question: 'نحوه احراز هویت چگونه است؟',
          answer: 'از طریق وب‌سایت یا اپلیکیشن تتر اکسچنج ، ابتدا در فرم کلی اطلاعاتی مانند نام، شماره موبایل و کد ملی را وارد کنید، سپس تصویر و ویدیوی مورد نیاز را طبق دستورالعمل ارائه شده بارگذاری کنید. در ادامه، منتظر بررسی احراز هویت توسط کارشناسان تتر اکسچنج باشید.'
        },
        {
          question: 'آیا خرید و فروش در تتر اکسچنج نیاز به احراز هویت دارد؟',
          answer: 'خیر، برای خرید و فروش تتر در تتر اکسچنج، نیاز به احراز هویت نمی باشد اما برای برداشت وجه حتما نیاز به احراز هویت در سایت می باشد..'
        },
        {
          question: 'آیا شماره موبایل باید تحت مالکیت دارنده حساب باشد؟',
          answer: 'بله، با توجه به شرایط و قوانین مجموعه تتر اکسچنج، ثبت شماره موبایل به نام و تحت مالکیت کاربر الزامی است.'
        },
        {
          question: 'آیا امکان احراز هویت اتباع وجود دارد؟',
          answer: 'متاسفانه تتر اکسچنج در حال حاضر امکان سرویس دهی به اتباع خارجی را ندارد.'
        },
        {
          title: 'خرید تتر'
        },
        {
          question: 'چگونه تتر بخرم؟',
          answer: 'خرید تتر از تتر اکسچنج با کارت بانکی بنام شخص احراز شده تا سقف 25 میلیون تومان و افزایش اعتبار کیف پول تومانی به صورت ۲۴ساعته و اتوماتیک از وب‌سایت یا اپلیکیشن تتر اکسچنج انجام می‌شود.'
        },
        {
          question: 'فرآیند خرید تتر در تتر اکسچنج به چه صورت می باشد؟',
          answer: '1.انجام فرآیند احراز هویت در وب‌سایت یا اپلیکیشن تتر اکسچنج 2.تایید شدن احراز هویت کاربر 3.ورود به حساب کاربر 4.انتخاب روش دریافت تتر (کیف تتر یا آدرس کیف‌پول دیگر) 5.پرداخت از طریق درگاه بانکی'
        },
        {
          question: 'زمان واریز تتر به چه صورت می باشد؟',
          answer: 'واریز تتر TRC-20 به‌صورت آنی و در لحظه انجام می‌گیرد. '
        },
        {
          question: 'کمترین مقدار تتری که امکان خرید وجود دارد چه مقدار است؟',
          answer: 'میزان حداقل برای خرید 5 تتر می باشد .'
        },
        {
          title: 'فروش تتر'
        },
        {
          question: 'چگونه تتر خود را به ریال تبدیل کنم؟',
          answer: 'برای تبدیل تتر به تومان، بعد از تکمیل فرآیند احراز هویت، از قسمت فروش تتر به ما مقدار تتری که می‌خواهید بفروشید را وارد کنید و در انتها اطلاعات خود را تکمیل کنید. واریز تومان از طرف تتر اکسچنج به حساب بانکی و یا کیف پول تومانی شما طبق شرایط و قوانین وب‌سایت انجام خواهد شد.'
        },
        {
          question: 'برداشت تتر در مجموعه به چه میزان زمان بر خواهد بود؟',
          answer: 'برداشت تتر تنها در اولین تراکنش 48 ساعت زمان بر خواهد بود و در سفارشات بعدی کاربران می توانند بصورت آنی تتر خود را دریافت نمایند.'
        },
        {
          question: 'حداقل مقدار تتر برای فروش چه قدر است؟',
          answer: 'حداقل مقدار تتر برای فروش به تتر اکسچنج، 5 تتر است.'
        },
        {
          question: 'حداکثر مقدار تتر برای فروش چه قدر است؟',
          answer: 'حداکثر مقدار تتر برای فروش به تتر اکسچنج، 200,000 تتر در هر شبانه‌روز است.'
        },
        {
          question: 'برای فروش تتر آیا ارائه رسید تراکنش نیاز است؟',
          answer: 'بله؛منظور همان TXID است که معمولاً 5 دقیقه پس از این‌که تتر را ارسال کردید در بخش Withdrawal History به شما نمایش داده خواهد شد.'
        },
        {
          title: 'کیف پول تتری'
        },
        {
          question: 'حداقل میزان قابل برداشت از کیف پول تتر چه مقدار است؟',
          answer: 'حداقل مقدار قابل برداشت از کیف تتر، 5 تتر TRC-20 است.'
        },
        {
          question: 'زمان برداشت از کیف پول تتر چقدر است؟',
          answer: 'برداشت تتر تنها در اولین تراکنش طبق قوانین پلیس فتا، 48 ساعت زمان بر خواهد بود و در سفارشات بعدی کاربران می توانند بصورت آنی تتر خود را دریافت نمایند.'
        },
        {
          question: 'کارمزد انتقال تتر داخلی بین کیف های تتر اکسچنج چه مقدار است؟',
          answer: 'بله؛ کارمزد جابجایی تتر از مبدا تتر اکسچنج به مقصد تتر اکسچنج رایگان است. شما می‌توانید به آدرس تتر دوستانتان در تتر اکسچنج بصورت رایگان تتر ارسال کنید.'
        },
        {
          title: 'کیف پول ریالی'
        },
        {
          question: 'آیا برای افزایش کیف پول ریالی محدودیتی وجود دارد؟',
          answer: 'حداکثر مقدار واریز و شارژ کیف پول ریالی 24,995,000 تومان می باشد برای واریز مبالغ بالاتر با پشتیبانی تتر اکسچنج در تماس باشید.'
        },
        {
          question: 'افزایش اعتبار کیف پول ریالی از چه طریق امکان پذیر می باشد؟',
          answer: 'پرداخت از طریق درگاه آنلاین (محدودیت 24,995,000 تومان در هر شبانه روز), دریافت شناسه واریز و انتقال از طریق پایا، ساتنا یا حساب به حساب بانکی '
        },
        {
          question: 'آیا برای استفاده از کیف پول ریالی نیاز به احراز هویت است؟',
          answer: 'بله؛ برای استفاده از کیف پول احراز هویت ضروری می باشد.'
        },
        {
          question: 'حداقل میزان واریز و برداشت از کیف پول ریالی چقدر است؟',
          answer: 'حداقل مبلغ قابل برداشت از کیف پول تومانی، 100 هزار تومان و حداقل مقدار به‌منظور واریز، 10 هزار تومان است.'
        },
        {
          title: 'دعوت دوستان'
        },
        {
          question: 'چگونه از طریق کد دعوت در تتر اکسچنج پاداش دریافت کنیم؟',
          answer: 'شما پس از ساخت حساب کاربری از طریق وب‌سایت یا اپلیکیشن تتر اکسچنج، کد معرف مخصوص به خود را در بخش دعوت دوستان حساب کاربری خود مشاهده خواهید کرد. با دعوت دوستان خود و ثبت‌نام آن‌ها با کد معرف شما، کسب پاداش شما به‌صورت دایمی امکان پذیر خواهد بود. '
        },
        {
          question: 'چه مقدار پاداش از طریق دعوت دوستان از مجموعه دریافت می کنیم؟',
          answer: 'به ازای خرید و فروش هر واحد تتر توسط دوست شما 0.1 از کل پرداختی، به‌صورت دایمی و مادام‌العمر به بخش اعتبارات شما در حساب کاربری افزوده خواهد شد.'
        },
        {
          question: 'چه زمانی مقادیر پاداش به حسابم اضافه می شود؟',
          answer: 'پاداش‌ها بلافاصله بعد از تراکنش به مجموع پاداش شما افزوده می‌شود و قابل مشاهده است.'
        },
        {
          question: 'آیا محدودیتی برای استفاده از کد دعوت داریم؟',
          answer: 'خیر، محدودیتی برای اشتراک گذاری کد دعوت وجود ندارد.'
        }
      ],
      structuredData: {
        "@context": "http://schema.org",
        "@type": "WebPage",
        name: "پاسخ کامل به سوالات متداول برای خرید و فروش تتر در سایت تتر اکسچنج",
        description:
          "تیم حرفه ای تتر اکسچنج تمامی سوالات شما در مورد خرید و فروش ارز تتر را آماده پاسخگویی شما عزیزان می باشد.",
        url: "https://tether-ex.com/faq",
        image: "https://tether-ex.com/logo.png",
        publisher: {
          "@type": "Organization",
          name: "بک کوین",
          logo: {
            "@type": "ImageObject",
            url: "https://tether-ex.com/logo.png",
            width: "200",
            height: "200",
          },
        },
        mainEntityOfPage: {
          "@type": "FaqPage",
          "@id": "https://tether-ex.com/faq",
          mainEntity: [],
        },
        datePublished: "2023-01-01",
        dateModified: "2023-01-01",
      },
    }
  },

  head() {
    this.structuredData.mainEntityOfPage.mainEntity = this.faq.map((item) => {
      if (!item.title) {
        let answer = "";
        if (item.answer) {
          answer = item.answer.replace(/<\/?[^>]+(>|$)/g, "");
          answer = answer.replace(/&nbsp;/g, " ");
        }
        return {
          "@type": "Question",
          name: item.question,
          acceptedAnswer: {
            "@type": "Answer",
            text: answer,
          },
        };
      }
    });
    return {
      title: "پاسخ کامل به سوالات متداول برای خرید و فروش تتر در سایت تتر اکسچنج",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "تیم حرفه ای تتر اکسچنج تمامی سوالات شما در مورد خرید و فروش ارز تتر را آماده پاسخگویی شما عزیزان می باشد.",
        },
        {
          hid: "keywords",
          name: "keywords",
          content: "سوالات متداول ارز های دیجیتال",
        },
      ],
      script: [{ type: "application/ld+json", json: this.structuredData }],
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          hreflang: "fa",
          href: "https://tether-ex.com/faq",
        },
      ],
    }
  },
}
