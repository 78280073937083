
export default {
  name: "buy",
  layout: "single",
  data() {
    return {
      amount: 0,
      createOrderFlag: false,
      selectedReceive: null,
      receives: [
        { label: "تراکنش آنی", disabled: false },
        { label: "تراکنش زماندار", disabled: true },
        // {icon: "/layouts/icons/networks/trx.svg", label: "ERC20"},
      ],
      cards: {
        selected: null,
        accounts: [],
        // options: [],
        addNewCard: {
          formFlag: false,
        }
      },
      lirPrice: 0,
      lirCards: {
        selected: null,
        accounts: [],
        // options: [],
        addNewCard: {
          formFlag: false,
        }
      },
    }
  },
  computed: {
    rialAmount() {
      return parseInt(this.$store.state.lirConfig.currentLirPrice * this.amount);
    },
    fee() {
      return parseFloat(parseFloat((this.amount / 100) * 0.3).toFixed(2));
    },
    lirOptions() {
      let accounts = this.lirCards.accounts;
      let newOptions = [];
      accounts.forEach(account => {
        newOptions.push({
          id: account.id,
          icon: account.turkishBank.logo,
          title: account.turkishBank.name,
          body: [
            `شماره IBAN:${account.iban}`,
            `نام صاحب حساب:${account.name}`,
          ]
        });
      });
      return newOptions;
    },
    cardsOptions() {
      let accounts = this.cards.accounts;
      let newOptions = [];
      accounts.forEach(account => {
        newOptions.push({
          id: account.id,
          icon: `${this.$config.cdnUrl}/${account.bank.image}`,
          title: account.bank.name,
          body: [
            `شماره کارت:${account.card}`,
            `شبا:${account.sheba}`,
          ]
        });
      });
      return newOptions;
    },
    lirSubmitted: {
      get() {
        return this.$store.state.addLirAccount.submitted
      },
      set() {
        this.$store.dispatch('addLirAccount/toggleSubmit')
      }
    }
  },
  watch: {
    lirSubmitted(newVal) {
      if (newVal === true) {
        this.getBankLirAccounts();
        this.lirSubmitted = false
      }
    },
  },
  methods: {
    goEdit() {
      this.$router.push('/');
    },
    async getBankLirAccounts() {
      try {
        let resp = await this.$axios.$get('/v1/lirAccount/all');
        this.lirPrice = resp.lirPrice.lir
        this.lirCards.accounts = resp.lirAccounts;
      } catch (error) {
        console.log(error)
      }
    },
    showAddLirAccount() {
      console.log('check1')
      this.$store.dispatch("addLirAccount/showPopup");
    },

    async getUrl() {
      try {
        let amountPay = this.rialAmount - this.$auth.user.balance.IRT
        if (amountPay < 10000) {
          this.$store.dispatch(
            "messagePopup/error",
            "مبلغ وارد شده کمتر از 10,000 تومان می باشد."
          );
          return;
        }
        if (!this.cards.selected) {
          this.$store.dispatch(
            "messagePopup/error",
            "یک شماره کارت کارت انتخاب نمایید."
          );
          return;
        }
        const response = await this.$axios.$post("/v1/payment", {
          amount: amountPay,
          accountId: this.cards.selected.id,
        });
        window.location.href = response.url;
      } catch (error) {
        this.$store.dispatch(
          "messagePopup/error",
          error.response.data.message,
          { root: true }
        );
      }
    },

    async lirWithdraw() {
      if (this.lirCards.selected) {
        if (this.$auth.user.isKYC) {
          if (this.rialAmount && this.rialAmount >= 10000) {
            if (this.rialAmount <= this.$auth.user.balance.IRT) {
              this.$store.dispatch("dashboardPopupForm/closePopup");
              this.$store.dispatch("overlay/toggleOverlay");
              try {
                let payload = {
                  type: "LIR",
                  amount: this.rialAmount,
                  lirAccountId: this.lirCards.selected.id,
                };
                // left here that server not responding as I want
                let resp = await this.$axios.$post('/v1/withdrawal', payload);
                await this.$store.dispatch("buySellLir/clearOrder");
                await this.$auth.fetchUser();
                this.$store.dispatch("messagePopup/success", `درخواست واریز لیر شما به مبلغ ${payload.amount} تومان با موفقیت ثبت شد.`);
                this.$store.dispatch("usdtConfig/set", resp.price);
                this.$store.dispatch("lirConfig/set", resp.lirPrice);
                await this.$router.push({ name: 'dashboard-wallet' });
              } catch (err) {
                this.$store.dispatch("messagePopup/error", err.response.data.message);
              } finally {
                this.$store.dispatch("overlay/toggleOverlay");
              }
            } else {
              this.$store.dispatch("messagePopup/error", "موجودی تومان شما کافی نمی باشد.");
            }
          } else {
            this.$store.dispatch("messagePopup/error", "حداقل مقدار برای خرید لیر معادل 5,000 تومان می باشد.");
          }
        }
        else {
          this.$store.dispatch("messagePopup/error", "برای برداشت از حساب ابتدا می بایست احراز هویت کنید.");
        }
      } else {
        this.$store.dispatch("messagePopup/error", "لطفا یک کارت بانکی جهت برداشت انتخاب کنید.");
      }
    },

    async getBankAccounts() {
      if (this.$store.state.bankAccounts.accounts.length === 0 && !this.$store.state.bankAccounts.loaded) {
        await this.$store.dispatch("bankAccounts/getAccounts");
        this.cards.accounts = this.$store.getters["bankAccounts/combine"];
      } else {
        this.cards.accounts = this.$store.getters["bankAccounts/combine"];
      }
    },
  },
  async fetch() {
    if (this.$store.state.buySellLir.type === 'buy' && this.$store.state.buySellLir.amount >= 10) {
      this.amount = this.$store.state.buySellLir.amount;
    }
    await this.getBankLirAccounts();
    await this.getBankAccounts();
  },
  beforeMount() {
    this.$store.dispatch("buySellLir/loadFromLocal");
    if (this.$store.state.buySellLir.type === 'buy' && this.$store.state.buySellLir.amount >= 1) {
      this.amount = this.$store.state.buySellLir.amount;
    } else {
      this.$router.push('/');
    }
  },
  destroyed() {
    if (this.$store.state.buySellLir.type && this.$store.state.buySellLir.amount) {
      this.$store.dispatch("buySellLir/setToLocal");
    }
  },
}
