import { render, staticRenderFns } from "./lir.vue?vue&type=template&id=cceb8e60&scoped=true"
import script from "./lir.vue?vue&type=script&lang=js"
export * from "./lir.vue?vue&type=script&lang=js"
import style0 from "./lir.vue?vue&type=style&index=0&id=cceb8e60&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cceb8e60",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSelectRadio: require('/app/components/core/select/Radio.vue').default,CoreInputNewInfoField: require('/app/components/core/input/NewInfoField.vue').default,CoreSelectCard: require('/app/components/core/select/Card.vue').default,CoreButton: require('/app/components/core/Button.vue').default,PublicLirIcon: require('/app/components/public/lir/Icon.vue').default})
